<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0"  v-bind:style=" showNepaliDatePicker ? 'height: 1250px;' : '' ">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h3>
                  Add student
                </h3>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                <router-link :to="{name:'students'}">
                  Students
                </router-link>
                \
                Add

              </div>
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <v-col cols="12">
                <v-col cols="12">
                  <h4>Personal information</h4> <hr/>
                </v-col>
                <v-row>
                  <div class="col-md-12 col-lg-12 col-sm-12 form-group">
                  <v-select outlined dense label="Title"
                            v-model="user.title"
                            :items="titleItems"
                            item-value="value"
                            item-text="text">
                  </v-select>
                </div>
                  <div class="col-md-4 form-group">
                  <v-text-field label="First name" :error="$v.user.first_name.$error" outlined dense v-model="user.first_name">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user.first_name.$error">This information is required.</span>
                </div>
                  <div class="col-md-4 form-group">
                  <v-text-field
                      label="Middle name"
                      outlined
                      dense
                      v-model="user.middle_name">
                  </v-text-field>
                </div>
                  <div class="col-md-4 form-group">
                  <v-text-field
                      label="Last name"
                      outlined
                      dense :error="$v.user.last_name.$error"
                      v-model="user.last_name">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user.last_name.$error">This information is required.</span>
                </div>
                  <v-col class="col-md-6 form-group">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="user.dob"
                            label="Date of birth (AD)"
                            append-icon="mdi-calendar"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          @change="convertAdToBs"
                          v-model="user.dob"
                          @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="col-md-6 form-group">
                    <v-text-field
                        v-if="!showNepaliDatePicker"
                        label="Date of birth (BS)"
                        outlined
                        :menu-props="{ top: true, offsetY: true }"
                        dense
                        v-model="user.dob_bs"
                        append-icon="mdi-calendar"
                        @click:append="showNepaliDatePicker=true;"
                    >
                    </v-text-field>

                    <v-nepali-date-picker
                        v-if="showNepaliDatePicker"
                        class="date_of_birth_custom"
                        @input="convertBsToAd"
                        :value="user.dob_bs"
                        v-model="user.dob_bs"
                        calenderType="Nepali"
                        placeholder="Date of birth (BS)"
                        format="YYYY-MM-DD"  :yearSelect="true" :monthSelect="true"></v-nepali-date-picker>

                  </v-col>
                  <v-col cols="12">
                    <h4>Contact information</h4> <hr/>
                  </v-col>
                  <div class="col-md-6 form-group">
                  <v-text-field
                      label="Mobile"
                      outlined
                      dense
                      v-model="user.mobile"
                      @blur="validatePhone"
                  >
                  </v-text-field>

                  <span class="text-danger" v-if="phone_exists">Mobile number in use</span>
                </div>
                  <div class="col-md-6 form-group">
                  <v-text-field
                      label="Phone"
                      outlined
                      dense
                      v-model="user.phone">
                  </v-text-field>
                </div>

                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <div class="col-md-12 col-lg-12 col-sm-12 form-group">
                    <v-text-field
                        label="Email"
                        type="email"
                        outlined :error="email_exists"
                        dense
                        :readonly="user.id"
                        v-model="user.email"
                        @blur="validateEmail"
                    >
                    </v-text-field>
                    <span class="text-danger" v-if="email_exists">Email already exists </span>
                  </div>
                  <div class="col-md-12">
                    <v-switch
                        v-model="user.is_email_verified"
                        label="Is this email address verified for login and communication purpose?"
                    >
                    </v-switch>
                  </div>
                  <v-col cols="12">
                    <h4>Academic information</h4> <hr/>
                  </v-col>
                  <div class="col-md-6 form-group">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="studentSetting.academic_year_id"
                        :items="academicYears"
                        label="Batch"
                        item-value="id"
                        item-text="year"
                        @change="changeBatch()"
                        :error="$v.studentSetting.academic_year_id.$error"
                    ></v-select>
                    <div class="text-danger mt-2" v-if="$v.studentSetting.academic_year_id.$error">This information is required.</div>

                    <!-- <span class="text-danger" v-if="$v.studentSetting.academic_year_id.$error">This information is required.</span> -->
                    <!--                                <span class="text-danger" v-if="$v.user.type.$error">Role is required</span>-->
                  </div>
                  <div class="col-md-6 form-group">
                    <!--                                <label for class="float-left">Level</label>-->
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="studentSetting.level_id"
                        :items="levels"
                        label="Level"
                        item-value="id"
                        item-text="title"
                        @change="getPrograms"
                        :error="$v.studentSetting.level_id.$error"
                    ></v-select>
                    <div class="text-danger mt-2" v-if="$v.studentSetting.level_id.$error">This information is required.</div>

                  </div>
                  <div class="col-md-6 form-group">
                    <!--                                <label for class="float-left">Programs</label>-->
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="studentSetting.program_id"
                        :items="programs"
                        label="Program"
                        item-value="id"
                        item-text="title"
                        :loading="programLoading"
                        @change="getGrades"
                        :error="$v.studentSetting.program_id.$error"
                    ></v-select>
                    <div class="text-danger mt-2" v-if="$v.user.type.$error">This information is required.</div>
                    <div class="text-danger mt-2" v-if="$v.studentSetting.program_id.$error">This information is required.</div>

                  </div>
                  <div class="col-md-6 col-sm-12 form-group">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="studentSetting.grade_id"
                        :items="gradesLevels"
                        label="Semester/Year"
                        item-value="id"
                        item-text="title"
                        :loading="gradeLoading"
                        @change="getAcademicClasses"
                        :error="$v.studentSetting.grade_id.$error"
                    ></v-select>
                    <div class="text-danger mt-2" v-if="$v.studentSetting.grade_id.$error">This information is required.</div>
                    <div class="text-danger mt-2" v-if="$v.user.type.$error">This information is required.</div>
                  </div>
                  <div class="col-12">
                    <v-textarea
                        name
                        outlined
                        dense
                        v-model="user.description"
                        placeholder="Notes during enrolment"
                    ></v-textarea>
                  </div>

                  <div class="col-md-12">
                    <v-switch
                        v-model="user.send_welcome_email"
                        label="Send a welcome email to student with their login details?"
                    >
                    </v-switch>
                  </div>
                  <div class="col-md-12">

                    <v-switch
                        v-model="user.is_active"
                        label="Enrolment status"
                    >
                    </v-switch>
                  </div>
                </v-row>
              </v-col>

            </div>


            <div class="col-12" v-if="user.image_path">
              <img
                  :src="user.image_path['thumb']"
                  alt
                  style="height:75px"
                  class="img-thumbnail"
              />
            </div>
            <div class="col-12 text-right">

              <v-btn
                  class="btn btn-standard cancel-btn"
                  depressed
                  @click="redirectTo"
              >Cancel
              </v-btn>
              <v-btn
                  class="btn btn-primary text-white"
                  depressed
                  @click="createOrUpdate"
                  :loading="isBusy"
              >Save
              </v-btn>

            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog persistent v-model="switchDialog" max-width="500">
      <v-card>
        <v-card-title>
          Student added
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex justify-space-between">

            <v-hover
                v-slot="{ hover }"
            >
              <v-card
                  @click="redirectToSummary()"
                  flat
                  :elevation="hover ? 2 : 0"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto activity-card cursor-pointer"
              >
                <v-card-text class="justify-center align-center">
                  <img src="media/images/edit-icon.png"
                       style="height: 100px; margin: 0 auto; display: block;">
                  <p class="font-weight-bold text-center mb-0 mt-3">
                    Edit this student</p>
                </v-card-text>
              </v-card>
            </v-hover>
            <v-hover
                v-slot="{ hover }"
            >
              <v-card
                  @click="redirectTo()"
                  flat
                  :elevation="hover ? 2 : 0"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto activity-card cursor-pointer"
              >
                <v-card-text class="justify-center align-center">
                  <img  src="media/images/list-icon.png"
                       style="height: 100px; margin: 0 auto; display: block;">
                  <p class="font-weight-bold text-center mb-0 mt-3">
                    Go to student list</p>
                </v-card-text>
              </v-card>
            </v-hover>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import VNepaliDatePicker from 'v-nepalidatepicker';
import NepaliDate from 'nepali-date-converter'

const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const enrollmentService = new UserEnrollmentService();
export default {
  name: "student-new",
  validations() {
    return {
      user: this.rules,
      studentSetting: this.studentSettingRules
    };
  },
  components: {
    VNepaliDatePicker,
  },
  data() {
    return {
      isBusy: false,
      switchDialog: false,
      sizeExceed: false,
      job_types: ["admin", "student", "coordinator", "principle"],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      errors: [],
      dob:null,
      titleItems: [
        {text: 'Mr', value: 'mr'},
        {text: 'Mrs', value: 'mrs'},
        {text: 'Ms', value: 'ms'},
        {text: 'Miss', value: 'miss'},
        {text: 'Dr.', value: 'dr'},
        {text: 'Other', value: ''},
      ],
      today: new Date().toISOString().substr(0,10),

      showNepaliDatePicker: true,
      emailError: null,
      levelId: null,
      menu2: false,
      email_exists: false,
      phone_exists: false,

      studentSetting: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        class_id: null,
      },
      newUser: {},
      user: {
        title: '',
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        mobile: null,
        description: null,
        notification_email: true,
        notification_sms: true,
        type: 'student',
        account_type: 'student',
        is_email_verified: true,
        is_active: true,
        dob: null,
        dob_bs:"",
        send_welcome_email:false,
      },
      programLoading: false,
      gradeLoading: false,
    };
  },

  mounted() {
    this.id ? this.getUser(this.id) : "";
    this.getLevels()
    this.getAcademicYears()

    if (this.studentSetting.level_id)
      this.getPrograms();
    if (this.studentSetting.program_id)
      this.getGrades();
    if (this.studentSetting.grade_id)
      this.getAcademicClasses();

  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    rules() {
      return {
        first_name: {required},
        last_name: {required},
        type: {required},
      };
    },

    studentSettingRules(){
      return {
        academic_year_id: {required},
        level_id: {required},
        program_id: {required},
        grade_id: {required},
        class_id: {},
      };
    }
  },

  methods: {
    addClass() {
      // Select the element using the size attribute
      let element = document.querySelector("[size='mini']");
      console.log(element)
      // Add a new class to the element
      // element.classList.add("newClass");
    },
    validateEmail() {
      // 'user/validate-email'
      let data = {
        email: this.user.email
      }
      if(this.user.email.length > 5){
        userService.validateEmail(data).then(res => {
          // console.log(res)
          if (res.data.status == 'OK' && res.data.user) {
            this.email_exists = true;
            this.$snotify.error('Email already exists please try new email ')
          } else {
            this.email_exists = false;
          }
        })
      }

    },
    validatePhone() {
      // 'user/validate-email'
      let data = {
        mobile: this.user.mobile
      }
      if(this.user.mobile.length>6){
        userService.validatePhone(data).then(res => {
          if (res.data.status == 'OK' && res.data.user){
            this.phone_exists = true;
            this.$snotify.error('Mobile number already in use')
          }
          else {
            this.phone_exists = false;
          }
        })
      }
    },

    getUser(id) {
      userService.show(id).then(response => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        if (this.user.setting) {
          this.studentSetting = this.user.setting
        }
        this.edit = true;
        this.getLevels()
      });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        this.levels = response.data;
        if (this.studentSetting.id && this.studentSetting.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      this.studentSetting.grade_id = null;
      this.gradesLevels = [];
      this.studentSetting.program_id = null;
      this.programs = [];

      this.programLoading = true;
      programService
      .getAllByLevelId(this.studentSetting.level_id)
      .then(response => {
        this.programs = response.data;
        this.programLoading = false;
      })
      .catch(err => {
        this.programLoading = false;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    changeBatch() {
      if (this.studentSetting.level_id)
        this.getPrograms();
      if (this.studentSetting.program_id)
        this.getGrades();
      if (this.studentSetting.grade_id)
        this.getAcademicClasses();

    },
    getGrades() {
      this.gradeLoading = true;
      gradeLevelService
      .getByProgram(this.studentSetting.program_id)
      .then(response => {
        this.gradesLevels = response.data;
        this.gradeLoading = false;
      })
      .catch((err) => {
        this.gradeLoading = false;
      })
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.studentSetting.academic_year_id, this.studentSetting.program_id, this.studentSetting.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },
    save(date) {
      this.$refs.menu2.save(date);
    },
    convertAdToBs() {
      let data = {
        dob: this.user.dob
      };
      enrollmentService
          .convertAdToBs(data)
          .then(response => {
            this.user.dob_bs = response.data.dob;
            this.showNepaliDatePicker =false;
          })
          .catch(error => {
            //console.log(error);
          });
    },
    convertBsToAd() {
        const {year,month,date} =  new NepaliDate(this.user.dob_bs).getAD();
        this.user.dob = `${year}-${month}-${date}`;
    },

    redirectTo() {
      this.$tabs.close()
      this.$tabs.refresh('/students')
    },
    redirectToSummary() {
      setTimeout(() => {
        return this.$router.push({
          name: "students-summary",
          params: {id: this.newUser.id, type: 'student'}
        });
      }, 100);
      this.$tabs.close()
    },
    saveStudent() {
      this.isBusy = true;
      userService
      .createByAdmin(this.user)
      .then(response => {
        if(response.data.status=='OK'){
          this.isBusy = false;
          this.$snotify.success("Student added");
          this.switchDialog = true;
          this.newUser = response.data.newUser;
        }else{
          this.isBusy = false;
          this.$snotify.error(response.data.msg)
          return
        }

        // this.$router.push({name: "students"});
      })
      .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.errors = error.response.data.errors;

          if (this.errors.email) {
            this.emailError = this.errors.email;
            this.$snotify.error(this.emailError[0]);
          } else {
            this.$snotify.error("Something went wrong");
          }
          this.isBusy = false;
      });

    },

    createOrUpdate() {
      this.$v.user.$touch();
      this.$v.studentSetting.$touch();

      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      }
      else if (this.$v.studentSetting.$error) {
        setTimeout(() => {
          this.$v.studentSetting.$reset();
        }, 3000);
      } else {
        this.user.setting = this.studentSetting
        if (this.edit) {
          delete this.user.password
          this.updateStudent();
        } else {
          this.saveStudent();
        }
      }
    },
    updateStudent() {
      this.isBusy = true;
      delete this.user.email;
      delete this.user.phone;
      userService
          .updateUser(this.user.id, this.user)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Student record updated");
            this.$tabs.close().then(response => {
              this.$router.push({name: "students"});
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Error updating record. Please try again later.");
          });
    }
  }
};
</script>

<style >
.calendar__month select {
  background-color: #f0f0f0 !important;
  border: 1px solid #ccc !important;
  padding: 5px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #333 !important;
}
</style>
